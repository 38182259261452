import 'ant-design-vue/dist/antd.css';

import Vue from 'vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
import userService from './services/user';
// TODO zhangzw

Vue.config.productionTip = false;
Vue.use(Antd);

const init = async () => {
  try {
    const userInfo = await userService.getUserInfo().catch(console.log);
    store.commit('setUserInfo', userInfo);

    new Vue({
      el: '#app',
      router,
      store,
      template: '<router-view></router-view>'
    });
  } catch (error) {
    console.log('init error>>>', error);
  }
};

init();
