import Vue from 'vue';
import Vuex from 'vuex';
import {UserRole} from "@/constants";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ),
    userInfo: {}
  },
  getters: {
    userInfo: (state) => {
      return state.userInfo
    },
    isAdmin: (state) => {
      console.log("userRole is " + state.userInfo.userRole)
      return state.userInfo.userRole === UserRole.ADMIN || state.userInfo.userRole === UserRole.SYSTEM_HIVE
    },
    isAgent: (state) => state.userInfo.userRole === 2
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    }
  }
});

export default store;
