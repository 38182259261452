export const UserRole = {
  SYSTEM_HIVE: 0,
  ADMIN: 1,
  AGENT: 2,
  USER: 3
};

export const labelCol = { span: 6 };
export const wrapperCol = { span: 14 };

export const TaskType = {
  // -1xx 待开发任务
  TASK_XHS_LIVE: { code: -100, description: '小红书直播场控' },

  // 0 默认
  TASK_UNKNOWN: { code: 0, description: 'UNKNOWN' },

  // 1xx 系统命令
  TASK_SYS_UPDATE_AUTOJS: { code: 101, description: '更新脚本' },
  TASK_SYS_OPEN_USB: { code: 102, description: '【System】打开 USB（慎重）' },

  // 2xx 管理员常用命令
  TASK_XHS_TYPE_EVERY_ACCOUNT_DF_SUCCESS: { code: 200, description: '一键小红书账号全量申请' },
  TASK_XHS_ZI_FA_YANG: { code: 201, description: '小红书自发' },
  TASK_XHS_TYPE_ACCOUNT_YANG: { code: 202, description: '小红书养号' },

  // 3xx 小红书命令
  TASK_XHS_SHUA: { code: 300, description: '小红书刷量' },
  TASK_XHS_DAI_FA_APPLY_BEE_SCREENSHOT: { code: 301, description: '申请小红书账号' },
  TASK_XHS_DAI_FA: { code: 302, description: '小红书代发' },
  TASK_XHS_SCREEN_SHOT: { code: 303, description: '指定小红书更新主页截图' }
};

export const BeePlatformType = {
  BEE_PLATFORM_UNKNOWN: { code: 0, description: '未知' },
  BEE_PLATFORM_XHS: { code: 1, description: '小红书' },
  BEE_PLATFORM_DY: { code: 2, description: '抖音' },
  BEE_PLATFORM_KS: { code: 3, description: '快手' }
};

export const ProjectType = {
  ALL: { code: 0, description: 'ALL' },
  BEE: { code: 1, description: 'Bee' },
  BEE_ACCOUNT: { code: 2, description: 'BeeAccount' }
};

// 使用示例
console.log(TaskType.TASK_XHS_LIVE); // { code: -100, description: "小红书直播场控" }
